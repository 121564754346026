const subtitlesFallbackLabel = 'Standard';

const languageLabels = {
  danish: { label: 'Dansk', codes: ['da', 'dan'] },
  english: { label: 'Engelsk', codes: ['en', 'eng'] },
  finnish: { label: 'Finsk', codes: ['fi', 'fin'] },
  norwegian: { label: 'Norsk', codes: ['no', 'nor', 'nb'] },
  swedish: { label: 'Svensk', codes: ['sv', 'swe'] },
  none: { label: 'Av', codes: ['none'] },
} as const;

export type LanguageCode = (typeof languageLabels)[keyof typeof languageLabels]['codes'][number];
type Label = (typeof languageLabels)[keyof typeof languageLabels]['label'];
export type LanguageKey = keyof typeof languageLabels;

const mutableCodeToLabelMap = new Map<LanguageCode, { label: Label; language: keyof typeof languageLabels }>();
(Object.keys(languageLabels) as LanguageKey[]).forEach(key => {
  const { label, codes } = languageLabels[key];
  codes.forEach((code: LanguageCode) => {
    mutableCodeToLabelMap.set(code, { label, language: key });
  });
});

const languageCodeToLabelMap: ReadonlyMap<LanguageCode, { label: Label; language: keyof typeof languageLabels }> =
  mutableCodeToLabelMap;

const norwegian = {
  player: {
    audio: 'Lyd',
    automatic: 'Automatisk',
    cast_to: 'Cast til [DEVICE]',
    casting_to: 'Caster til [DEVICE]',
    casting_to_init: 'Caster til ...',
    chromecast: 'Chromecast',
    chromecast_connected: 'Chromecast forbundet',
    chromecast_connecting: 'Chromecast forbinder',
    chromecast_unavailable: 'Chromecast ikke tilgjengelig',
    continue_watching: 'Fortsett å se',
    fullscreen: 'Fullskjerm',
    shortcuts: 'Liste over hurtigtaster',
    pictureInPicture: 'Bilde i bilde',
    languagesettings: 'Språkvelger',
    live: 'Direkte',
    mute: 'Mute',
    pause: 'Pause',
    play: 'Spill av',
    playNextEpisode: 'Neste Episode',
    quality: 'Strømmekvalitet',
    seekbackward: 'Spol 10 sekunder tilbake',
    seekforward: 'Spol 10 sekunder frem',
    background_image: 'Film eller serie bilde',
    startagain: 'Start forfra',
    startover: 'Start forfra',
    subtitles: 'Undertekst',
    volume: 'Lyd av og på',
  },
  labels: languageLabels,
};

const language = norwegian;

const labelFor = (key: LanguageCode | undefined): string => {
  if (!key) return subtitlesFallbackLabel;
  return languageCodeToLabelMap.get(key)?.label ?? subtitlesFallbackLabel;
};

export { labelFor, language, languageCodeToLabelMap };
